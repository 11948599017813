import moment from "moment";

export const ONE_DAY_IN_MILLIS = 86400000;
export const LAST_28_DAYS = 28;

export function getWeeklyPeriodFor(endOfWeekDate: Date): string {
    const startOfWeekDate = getStartDateForTimePeriodInDays(endOfWeekDate, 7);

    return formatDurationTextFor(startOfWeekDate.toString(), endOfWeekDate.toString());
}

export function getWeekStartAndEndInMillis(endOfWeekDate: Date): [number, number] {
    const startOfWeekDate = getStartDateForTimePeriodInDays(endOfWeekDate, 7);

    return [startOfWeekDate.getTime(), endOfWeekDate.getTime()];
}

export function getStartDateForTimePeriodInDays(endDate: Date, timePeriod: number): Date {
    const startDate = new Date();
    startDate.setTime(endDate.getTime() - ((timePeriod - 1) * ONE_DAY_IN_MILLIS));

    return startDate;
}

export function getStartAndEndDatesForLast28Days(): string {
    const endDate = new Date();
    const startDate = getStartDateForTimePeriodInDays(endDate, LAST_28_DAYS);

    return formatDurationTextFor(startDate.toString(), endDate.toString());
}

export function isWithinWeek(dateCreated: string, startAndEndInMills: [number, number]): boolean {
    return Date.parse(dateCreated) > startAndEndInMills[0] && Date.parse(dateCreated) < startAndEndInMills[1];
}

export function getFormattedDayDateFromISOString(date: string): string {
    date = moment(date, "YYYY-MM-DD hh:mm:ss A").toString();
    return getFormattedDayDateFrom(date);
}

export function getFormattedDayDateFrom(date: string): string {
    return `${extractWeekdayFrom(date)} ${extractMonthFrom(date)} ${extractDayOfMonthFrom(date)}`;
}

export function formatDateToISOTwelveHourClock(date: Date): string {
    return moment(date).format("YYYY-MM-DD-h:mm:ss A");
}

export function formatDateForGraph(date: string): string {
    const isoDate = moment(date, "YYYY-MM-DDThh:mm:ssz").toISOString();
    return isoDate.slice(0, -5);
}

function extractWeekdayFrom(dateCreated: string): string {
    return dateCreated.slice(0, 3);
}

function extractMonthFrom(dateCreated: string): string {
    return dateCreated.slice(4, 7);
}

function extractDayOfMonthFrom(dateCreated: string): string {
    return dateCreated.slice(8, 10);
}

function formatDurationTextFor(startDate: string, endDate: string): string {
    return `${extractMonthFrom(startDate)} ${extractDayOfMonthFrom(startDate)} - ` +
        `${extractMonthFrom(endDate)} ${extractDayOfMonthFrom(endDate)}`;
}
