import {HiFiveResponse} from "../utilities/types/AWSResponses";
import {HiFiveGraphPlot} from "../model/HiFiveGraphPlot";
import {
    ONE_DAY_IN_MILLIS,
    getFormattedDayDateFrom,
    getWeeklyPeriodFor,
    getWeekStartAndEndInMillis,
    isWithinWeek, getFormattedDayDateFromISOString
} from "../utilities/date/DateUtils";

export function mapToWeeklyHiFiveGraphPlots(hiFives: HiFiveResponse[]): HiFiveGraphPlot[] {
    const hiFiveGraphPlots: HiFiveGraphPlot[] = createInitialWeeklyPlots();

    hiFives.forEach(hiFive => {
        const currentDate = getFormattedDayDateFromISOString(hiFive.dateCreated);
        const targetPlot = hiFiveGraphPlots.find(plot => plot.name === currentDate);
        if (targetPlot) hiFive.isPublic ? targetPlot.hiFives++ : targetPlot.loFives++;
    });

    return hiFiveGraphPlots;
}

export function mapToMonthlyHiFiveGraphPlots(hiFives: HiFiveResponse[]): HiFiveGraphPlot[] {
    const hiFiveGraphPlots: HiFiveGraphPlot[] = createInitialMonthlyPlots();

    hiFives.forEach(hiFive => {
        const targetPlot = hiFiveGraphPlots.find(plot => isWithinWeek(hiFive.dateCreated, plot.weekStartAndEndInMillis!));
        if (targetPlot) hiFive.isPublic ? targetPlot.hiFives++ : targetPlot.loFives++;
    });

    return hiFiveGraphPlots;
}

function createInitialWeeklyPlots(): HiFiveGraphPlot[] {
    const weeklyPlots: HiFiveGraphPlot[] = [];

    for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setTime(date.getTime() - (i * ONE_DAY_IN_MILLIS));

        weeklyPlots.unshift(
            {
                name: getFormattedDayDateFrom(date.toString()),
                loFives: 0,
                hiFives: 0
            }
        );
    }

    return weeklyPlots;
}

function createInitialMonthlyPlots(): HiFiveGraphPlot[] {
    const monthlyPlots: HiFiveGraphPlot[] = [];

    for (let i = 0; i < 28; i = i + 7) {
        const date = new Date();
        date.setTime(date.getTime() - (i * ONE_DAY_IN_MILLIS));

        monthlyPlots.unshift(
            {
                name: getWeeklyPeriodFor(date),
                hiFives: 0,
                loFives: 0,
                weekStartAndEndInMillis: getWeekStartAndEndInMillis(date)
            }
        );
    }

    return monthlyPlots;
}