import {FC} from "react";
import {User} from "../../model/User";
import {Link} from "react-router-dom";

interface ItemProps {
    itemText: string,
    durationText: string,
    users: User[],
}

export const InteractionReportPanelItem: FC<ItemProps> = ({
    itemText,
    durationText,
    users,
}: ItemProps): JSX.Element => {
    return (
        <>
            <div className="interactionReportPanelItem">
                <div className="itemHeader">
                    <h4 className="reportCardHeading">{itemText}</h4>
                    <div className="monthText">{durationText}</div>
                </div>
                <div className="interactionReportPanelUserContainer">
                    {users.map(user =>
                        <div key={user.id}>
                            <Link to={`/report/${user.id}`}>
                                <img
                                    title={user.displayName || user.realName || "Unknown user"}
                                    alt="Profile picture"
                                    className={`userProfile ${user.profileImageUrl ? "" : "unknownProfilePicture"}`}
                                    src={user.profileImageUrl || "https://i1.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0000-512.png?ssl=1"}
                                />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};