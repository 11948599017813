import {FC} from "react";
import logo from "../navbar/InsideInstil.png";
import "./MenuNavBar.css";
import {Link, useLocation} from "react-router-dom";
import {ImCross} from "react-icons/im";

export const MenuNavBar: FC = () => {
    const location = useLocation();
    const {previousPath} = location.state;

    return (
        <div className="menuNavbar">
            <Link to="/dashboard" className="instilLogo"><img className="instilLogo" src={logo} alt="Instil Logo"/></Link>
            <div className="menu-nav-items">
                <Link to={previousPath}>
                    <div>
                        <ImCross id="xIcon"/>
                    </div>
                </Link>
            </div>
        </div>
    );
};