import {FC, useEffect, useState} from "react";
import "./UserMoodSummary.css";
import {Survey} from "../../API";
import {surveyService} from "../../services/SurveyService/SurveyService";
import {get2WeekAverageMood, getUserAverageMood, unknownEmoji} from "../../model/Mood";
import {formatDateToISOTwelveHourClock, getStartDateForTimePeriodInDays} from "../../utilities/date/DateUtils";
import {User} from "../../model/User";

type UserMoodSummaryProps = {
    user: User
};

export type MoodEmojiDetails = {
    src: string,
    altDescription: string
}

export const UserMoodSummary: FC<UserMoodSummaryProps> = ({user}) => {
    const [surveys, setSurveys] = useState<Survey[]>([]);
    const [userAverageMood, setUserAverageMood] = useState<MoodEmojiDetails>({src: unknownEmoji, altDescription: "Unknown"});

    const weeksToBeCalculated = [6,4,2];

    function get2WeekAverageImageDetails(weekBeingCalculatedIndex: number): MoodEmojiDetails{
        return get2WeekAverageMood(weekBeingCalculatedIndex, surveys);
    }

    useEffect(() => {
        async function getAllSurveys(): Promise<void> {
            const startDate = formatDateToISOTwelveHourClock(getStartDateForTimePeriodInDays(new Date(), 365));
            const surveys = await surveyService.getSurveysByUserAndDate(user.id, startDate, formatDateToISOTwelveHourClock(new Date()));

            setSurveys(surveys);
            setUserAverageMood(getUserAverageMood(surveys));
        }
        getAllSurveys();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id]);

    return (
        <>
            <div className="userMoodSummaryPanel">
                <div className="flexLine">
                    <img className="userProfilePicture" src={user.profileImageUrl as string} alt={user.realName as string}/>
                      <span className="individualsName">{user.realName}</span>
                </div>
                <div className="flexLine userInfo">
                    <p className="roleBox">{user.jobTitle}</p>
                    <p className="team">{user.team}</p>
                </div>
            </div>
            <div className="uniformPanel">
                <p className="last6Weeks">Last 6 weeks:</p>
                <div className="sixWeekMoodImageSummary">
                    {weeksToBeCalculated.map(weekIndex => {
                        const imageDetails = get2WeekAverageImageDetails(weekIndex);
                        return (
                            <img key={weekIndex} className="sixWeekMoodImage" src={imageDetails.src} alt={imageDetails.altDescription} />
                        );}
                    )}
                </div>
            </div>
            <div className="uniformPanel">
                <p className="userAverage">User average:</p>
                <div className="averageMoodImageContainer">
                  <img className="averageMoodImage" src={userAverageMood.src} alt={userAverageMood.altDescription} />
                </div>
            </div>
        </>
    );
};