import {FC, useEffect, useState} from "react";
import "./InteractionReportPanel.css";
import {InteractionReportPanelItem} from "./InteractionReportPanelItem";
import {User} from "../../model/User";
import {getStartAndEndDatesForLast28Days, getWeeklyPeriodFor} from "../../utilities/date/DateUtils";
import {reportService} from "../../services/ReportService/ReportService";
import {HiFive} from "../../model/HiFive";
import {Tab} from "../../model/Tab";

interface PanelProps {
    users: User[];
    weeklyHiFives: HiFive[];
    monthlyHiFives: HiFive[];
    activeTab: Tab;
}

export const InteractionReportPanel: FC<PanelProps> = ({
    users,
    weeklyHiFives,
    monthlyHiFives,
    activeTab
}: PanelProps) => {
    const [mostAcknowledgedUsers, setMostAcknowledgedUsers] = useState<User[]>([]);
    const [leastAcknowledgedUsers, setLeastAcknowledgedUsers] = useState<User[]>([]);
    const [mostActiveUsers, setMostActiveUsers] = useState<User[]>([]);
    const [leastActiveUsers, setLeastActiveUsers] = useState<User[]>([]);
    const durationText = activeTab === Tab.Weekly ? getWeeklyPeriodFor(new Date()) : getStartAndEndDatesForLast28Days();

    useEffect(() => {
        async function getInteractionReportPanelData(): Promise<void> {
            const hiFives = activeTab === Tab.Weekly ? weeklyHiFives : monthlyHiFives;
            setMostAcknowledgedUsers(reportService.getMostAcknowledgedUsers(users, hiFives));
            setLeastAcknowledgedUsers(reportService.getLeastAcknowledgedUsers(users, hiFives));
            setMostActiveUsers(reportService.getMostActiveUsers(users, hiFives));
            setLeastActiveUsers(reportService.getLeastActiveUsers(users, hiFives));
        }
        getInteractionReportPanelData().catch(console.error);
    }, [users, weeklyHiFives, monthlyHiFives, activeTab]);

    return (
        <>
            <div className="interactionReportPanel">
                    <InteractionReportPanelItem itemText="Most Acknowledged" users={mostAcknowledgedUsers} durationText={durationText}/>
                    <InteractionReportPanelItem itemText="Least Acknowledged" users={leastAcknowledgedUsers} durationText={durationText}/>
                    <InteractionReportPanelItem itemText="Most Active" users={mostActiveUsers} durationText={durationText}/>
                    <InteractionReportPanelItem itemText="Least Active" users={leastActiveUsers} durationText={durationText}/>
            </div>
        </>
    );
};
