import {FC} from "react";
import "./UserReport.css";
import {Footer} from "../footer/Footer";
import {NavBar} from "../navbar/NavBar";
import {VscChevronLeft} from "react-icons/vsc";
import {SurveyReplies} from "../surveyReplies/SurveyReplies";
import {UserMoodSummary} from "../userMoodSummary/UserMoodSummary";
import {Link, useLocation, useParams} from "react-router-dom";
import {User} from "../../model/User";

type UserReportProps = {
    users: User[]
}

const nonExistentUser: User = {
    id: "id",
    realName: "User does not exist",
    profileImageUrl: "/images/Profile/PlaceholderProfileImage.jpeg"
};

export const UserReport: FC<UserReportProps> = ({users}) => {
    const {userId} = useParams<{userId: string}>();
    const user = users.find(user => user.id === userId) ?? nonExistentUser;

    return (
        <>
            <NavBar previousPath={useLocation().pathname}/>
            <div className="reportBox">
                <Link className="menu-button" to="/dashboard">
                    <VscChevronLeft/>Back to Main Report
                </Link>
            </div>
            <div className="reportGrid" >
                <div className="item1">
                    <UserMoodSummary user={user}/>
                </div>
                <div className="item2">
                    <div className="surveyResponses"><p className="surveyResponseText">Survey Responses</p></div>
                    <SurveyReplies index={0} question="What has gone well this week" user={user}/>
                    <SurveyReplies index={1} question="Do you need help with anything this week" user={user}/>
                </div>
            </div>
            <Footer/>
        </>
    );
};