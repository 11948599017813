import {FC} from "react";
import {Profile, ProfileCard} from "../../profile/ProfileCard";
import "./ProfileCardPanel.css";

interface ProfileCardPanelProps{
    profiles: Profile[]
}

export const ProfileCardPanel: FC<ProfileCardPanelProps> = ({
    profiles
}) => {
    return(
        <div>
            <div className="profileCardContainer">
                <div className="profileCardFlex">
                    {profiles.map((profile): JSX.Element => {
                            return (
                                <ProfileCard key={profile.id} profile={profile}/>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
};
