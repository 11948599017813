import {Filter} from "../components/WellbeingPanel/WellbeingPanel";
import {Profile} from "../components/profile/ProfileCard";
import {User} from "../model/User";
import {Survey} from "../API";
import {Mood} from "../model/Mood";
import {capitalise} from "../utilities/string/StringUtils";

export const placeholderProfileImage: string = "/images/Profile/PlaceholderProfileImage.jpeg";

export function populateTeams(profiles: Profile[]): Filter[] {
    return populateFilterOptions(profiles, "team");
}

export function populateRoles(profiles: Profile[]): Filter[]{
    return populateFilterOptions(profiles, "jobTitle");
}

export function populateFilterOptions(profiles: Profile[], key: "jobTitle" | "team"): Filter[]{
    const filterOptions: Filter[] = [];
    profiles.forEach(profile => {
        if (!isFilterPresent(filterOptions, profile[`${key}`])) {
            const filterOption: Filter = {
                type: key,
                value: profile[`${key}`]
            };
            filterOptions.push(filterOption);
        }
    });
    return filterOptions;
}

function isFilterPresent(filters: Filter[], filterValue: string): boolean {
    return filters.filter(filter => filter["value"] === filterValue).length > 0 && filterValue !== "null" && filterValue !== null && filterValue !== undefined;
}

export function convertUsersToProfiles(users: User[], surveys: Survey[]): Profile[] {
    const profiles: Profile[] = [];
    for (const user of users) {
        const profileSurveys = getSurveysForUser(user.id, surveys);
        const profile: Profile = {
            id: (user.id) ? user.id : "Unknown",
            profileImage: (user.profileImageUrl) ? user.profileImageUrl : placeholderProfileImage,
            firstName: (user.realName) ? user.realName.split(" ")[0] : "Unknown",
            surname: (user.realName) ? user.realName.split(" ").slice(1).join(" ") : "Unknown",
            slackDisplayName: (user.displayName) ? user.displayName : "Unknown",
            team: (user.team) ? user.team : "Unknown",
            jobTitle: user.jobTitle ? user.jobTitle : "Unknown",
            mood: getUserMood(profileSurveys)
        };
        profiles.push(profile);
    }
    return profiles;
}

function getSurveysForUser(userId: string, surveys: Survey[]): Survey[] {
    return surveys.filter(survey => survey.userId === userId);
}

function getUserMood(surveys: Survey[]): Mood {
    return (surveys === undefined || surveys.length === 0) ? "Unknown" : capitalise(surveys[0].mood) as Mood;
}
