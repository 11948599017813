import {User} from "../../model/User";
import {ReportServiceImpl} from "./ReportServiceImpl";
import {HiFive} from "../../model/HiFive";

/**
 * Returns sorted lists of users who sent/received the most/least HiFives in a given timeframe
 */
export interface ReportService {
    getMostAcknowledgedUsers(allUsers: User[], hiFives: HiFive[]): User[];
    getLeastAcknowledgedUsers(allUsers: User[], hiFives: HiFive[]): User[];
    getMostActiveUsers(allUsers: User[], hiFives: HiFive[]): User[];
    getLeastActiveUsers(allUsers: User[], hiFives: HiFive[]): User[];
}

export const reportService: ReportService = new ReportServiceImpl();