import {FC, useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import {Login} from "./components/login/Login";
import {Dashboard} from "./components/dashboard/Dashboard";
import {ProtectedRoute} from "./components/auth/ProtectedRoute";
import {NavMenu} from "./components/navMenu/NavMenu";
import {UserReport} from "./components/userReport/UserReport";
import {userService} from "./services/UserService/UserService";
import {User} from "./model/User";
import "./index.css";

const DashboardRoute: FC = () => <ProtectedRoute redirect="/" />;

const App: FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);

    useEffect(() => {
        async function getAllUsers(): Promise<void> {
            const users = await userService.getAllUsers();
            setUsers(users);
            setIsLoadingUsers(false);
        }

        getAllUsers().catch(console.error);
    }, []);

    return (
        <>
            {!isLoadingUsers &&
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route element={<DashboardRoute/>}>
                        <Route path="/dashboard" element={<Dashboard users={users}/>}/>
                    </Route>
                    <Route path="/menu" element={<NavMenu/>}/>
                    <Route path={"/report/:userId"} element={<UserReport users={users}/>}/>
                </Routes>
            }
        </>
    );
};

export default App;
