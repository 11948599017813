import {UserResponse} from "../../utilities/types/AWSResponses";
import {executeGraphQlOperation} from "../../utilities/graphql/HelperFunctions";
import {GetAllUsersQuery} from "../../API";
import {graphqlOperation} from "aws-amplify";
import {getAllUsers} from "../../graphql/queries";
import {UserService} from "./UserService";

export class UserServiceImpl implements UserService {
	getAllUsers = async (): Promise<UserResponse[]> => {
		try {
			const userResponse = await executeGraphQlOperation<GetAllUsersQuery>(graphqlOperation(getAllUsers, {}));
			return userResponse.data?.getAllUsers?.items as UserResponse[];
		} catch (error) {
			console.log(`Failed to fetch User Data: ${error}`);
			return [] as UserResponse[];
		}
	};
}