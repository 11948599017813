import {FC, useEffect, useState} from "react";
import "./WellbeingPanel.css";
import {Toolbar} from "./Toolbar/Toolbar";
import {ProfileCardPanel} from "./ProfileCardPanel/ProfileCardPanel";
import {populateRoles, populateTeams} from "../../mappers/UserMapper";
import {Profile} from "../profile/ProfileCard";
import {getFilterOptionsForMood} from "../../model/Mood";

export interface Filter {
    type: string,
    value: string
}

type WellbeingPanelProps = {
    profiles: Profile[];
}

interface filterTypes {
    team: string,
    jobTitle: string,
    mood: string
}

export function getProfilesMatchingFilter(profiles: Profile[], selectedFilters: Filter[], filterType: keyof filterTypes): Profile[] {
    if (selectedFilters.filter(selectedFilter => selectedFilter.type === filterType).length === 0) {
        return profiles;
    }
    return filterProfiles(profiles, selectedFilters, filterType);
}

function filterProfiles(profiles: Profile[], selectedFilters: Filter[], filterType: keyof filterTypes): Profile[] {
    const filteredProfiles: Profile[] = [];
    selectedFilters.forEach(filter => {
        if (filter.type === filterType) {
            profiles.forEach(profile => {
                if (isMatchingProfile(profile, filter)) {
                    filteredProfiles.push(profile);
                }
            });
        }
    });
    return filteredProfiles;
}

export function isMatchingProfile(profile: Profile, filter: Filter): boolean {
    const profileFilterValue = getProfileValueFromMatchingFilterType(profile, filter);
    return (profileFilterValue === filter.value);
}

export function getProfileValueFromMatchingFilterType(profile: Profile, filter: Filter): string | undefined | null {
    const type: (keyof filterTypes) = filter.type as keyof(filterTypes);
    return profile[type];
}

export function getProfilesMatchingAllFilterTypes(teamProfiles: Profile[], roleProfiles: Profile[], moodProfiles: Profile[]): Profile[] {
    return teamProfiles.filter(teamProfile => roleProfiles.some(roleProfile => teamProfile.id === roleProfile.id))
        .filter(filteredProfile => moodProfiles.some(moodProfile => filteredProfile.id === moodProfile.id));
}

export function searchUsername(profiles: Profile[], searchText: string): Profile[] {
    return profiles.filter(
        profile => `${profile.firstName?.toLowerCase()} ${profile.surname?.toLowerCase()}`.includes(searchText.toLowerCase()));
}

export const WellbeingPanel: FC<WellbeingPanelProps> = ({
    profiles
}) => {
    const [filteredProfiles, setFilteredProfiles] = useState<Profile[]>(profiles);
    const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const teams: Filter[] = populateTeams(profiles);
    const roles: Filter[] = populateRoles(profiles);

    useEffect(() => {
        async function filterAndSearchProfiles(): Promise<void> {
            let filteredProfiles = profiles;
            if (selectedFilters.length > 0) {
                filteredProfiles = getProfilesMatchingAllFilterTypes(
                    getProfilesMatchingFilter(profiles, selectedFilters, "team"),
                    getProfilesMatchingFilter(profiles, selectedFilters, "jobTitle"),
                    getProfilesMatchingFilter(profiles, selectedFilters, "mood"));
            }
            if (searchText.length > 0) {
                filteredProfiles = searchUsername(filteredProfiles, searchText);
            }
            setFilteredProfiles(filteredProfiles);
        }

        filterAndSearchProfiles();
    }, [selectedFilters, searchText, profiles]);

    function addToFilterSelection(selectedFilter: Filter): void {
        if (!selectedFilters.includes(selectedFilter)) {
            setSelectedFilters([...selectedFilters, selectedFilter]);
        }
    }

    function removeSelectedFilter(selectedFilter: Filter): void {
        setSelectedFilters(selectedFilters.filter(filter => {
            return filter.type !== selectedFilter.type ? true : (filter.value !== selectedFilter.value);
        }));
    }

    return (
        <div className="anchorDiv">
            <Toolbar searchText={searchText}
                     setSearchText={setSearchText}
                     selectedFilters={selectedFilters}
                     addToSelectedFilters={addToFilterSelection}
                     removeSelectedFilter={removeSelectedFilter}
                     teams={teams}
                     roles={roles}
                     moods={getFilterOptionsForMood()} />
            <ProfileCardPanel profiles={filteredProfiles}/>
        </div>
    );
};
