import {FC, useEffect, useState} from "react";
import "./ProfileCard.css";
import {getEmojiFromMood, Mood} from "../../model/Mood";
import {Link} from "react-router-dom";

export type ProfileCardProps = {
    profile: Profile;
}

export interface Profile {
    id: string,
    profileImage: string,
    firstName: string,
    surname: string,
    slackDisplayName: string,
    team: string,
    jobTitle: string,
    mood: Mood
}

interface EmojiImageDetails {
    imageSrc: string,
    altDescription: string
}

export const ProfileCard: FC<ProfileCardProps> = ({profile}) => {
    const [mood, setMood] = useState<string>();
    const [altDescription, setAltDescription] = useState<string>();

    async function getMostRecentMoodResponse(): Promise<EmojiImageDetails>{
        const emoji = getEmojiFromMood(profile.mood);
        const imageSrc = emoji;
        return {
            imageSrc,
            altDescription: profile.mood
        };
    }

    useEffect(() => {
        async function setMostRecentMood(): Promise<void> {
            const mostRecentMood = await getMostRecentMoodResponse();

            setMood(mostRecentMood.imageSrc);
            setAltDescription(mostRecentMood.altDescription);
        }

        setMostRecentMood();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>
            <div className="external">
                <Link to={`/report/${profile.id}`}>
                <div className="flex">
                    <img className="profileImage" src={profile.profileImage} alt="Profile Image"></img>
                    <div className="nameTag">
                            <h1>{profile.firstName}</h1>
                            <h1>{profile.surname}</h1>
                    </div>
                    <div>
                    <span className="slider"></span>
                    <h3>{profile.team}</h3>
                    </div>
                    <div className="horizontal">
                        <div className="jobTitle" title={profile.jobTitle}>
                            <h2>{profile.jobTitle}</h2>
                        </div>
                        <img className="emoji" src={mood} alt={altDescription}></img>
                    </div>
                </div>
                </Link>
            </div>
        </>
    );
};