import {FC, useState} from "react";
import {FilterPanel} from "../FilterPanel";
import {Filter} from "../WellbeingPanel";
import "./Toolbar.css";
import {SearchBar} from "./SearchBar/SearchBar";
import {capitalise} from "../../../utilities/string/StringUtils";

interface ToolbarProps{
    searchText: string
    setSearchText: (input: string) => void
    selectedFilters: Filter[]
    addToSelectedFilters: (filter: Filter) => void
    removeSelectedFilter: (filter: Filter) => void
    teams: Filter[]
    moods: Filter[]
    roles: Filter[]
}

export const Toolbar: FC<ToolbarProps> = ({
  searchText,
  setSearchText,
  selectedFilters,
  addToSelectedFilters,
  removeSelectedFilter,
  teams,
  moods,
  roles
}) => {
    const [currentFilterId, setCurrentFilterId] = useState<string>("");
    const teamFilterPanel = buildFilterPanel("Team", teams);
    const moodFilterPanel = buildFilterPanel("Mood", moods);
    const jobTitleFilterPanel = buildFilterPanel("JobTitle", roles);

    function buildFilterPanel(filterType: string, filterList: Filter[]): JSX.Element {
        return (<FilterPanel filterId={filterType} currentlySelectedFilterId={currentFilterId} setCurrentlySelectedFilterId={setCurrentFilterId} addToSelectedFilters={addToSelectedFilters} removeSelectedFilter={removeSelectedFilter} filterOptions={filterList} alreadySelectedFilters={selectedFilters}
                             filterText={filterType}/>);
    }

    return (
        <div>
            <div className="toolbarSectionDivider">
                <h1 className="toolbarTitle">Wellbeing</h1>
                {teamFilterPanel}
                {moodFilterPanel}
                {jobTitleFilterPanel}
            </div>
            <div className="toolbarSelectedFilterList toolbarSectionDivider">
                <SearchBar searchText={searchText} setSearchText={setSearchText}/>
                <div className="filterButtonContainer">
                    {selectedFilters.map((
                            filter: Filter, index: number): JSX.Element => {
                            return (
                                <div key={`${filter.type}_${filter.value}`} className="individualSelectedFilters">
                                    <button className="alignButtonContents removeFilterButton"
                                            key={index}
                                            onClick={() => removeSelectedFilter(filter)}
                                            title={capitalise(filter.type)}>
                                        <p>{filter.value}</p>
                                    </button>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
};