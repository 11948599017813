import {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {checkUserHasAdminGroup} from "../../services/Authentication/AuthenticationService";
import {Navigate, Outlet} from "react-router-dom";

type BooleanSetState = Dispatch<SetStateAction<boolean>>;

interface ProtectedRouteProps {
    redirect: string;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({redirect}) => {
    const [isAuthorised, setIsAuthorised] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function authenticate(): Promise<void> {
            try {
                const result = await checkUserHasAdminGroup();
                setAuthorisedValue(setIsAuthorised, result, setIsLoading);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                logErrorAndSetAsUnauthorised(error, setIsAuthorised, setIsLoading);
            }
        }
        authenticate();
    }, []);

    return (
        isLoading ? <></> : isAuthorised ? <Outlet /> : <Navigate to={redirect}/>
    );
};

const logErrorAndSetAsUnauthorised = (error: Error, setAuthorisedState: BooleanSetState, updateLoadingState: BooleanSetState): void => {
    console.error(`There was an error while trying to authenticate you as an administrator: ${error}`);
    setAuthorisedValue(setAuthorisedState, false, updateLoadingState);
};

const setAuthorisedValue = (setIsAuthorised: BooleanSetState, authorised: boolean, setLoadingState: BooleanSetState): void => {
    setIsAuthorised(authorised);
    setLoadingState(false);
};