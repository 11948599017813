import {FC, useEffect, useState} from "react";
import {HiFiveChart} from "../charts/HiFiveChart";
import {NavBar} from "../navbar/NavBar";
import {Footer} from "../footer/Footer";
import {HiFiveResponse} from "../../utilities/types/AWSResponses";
import {hiFiveService} from "../../services/HiFiveService/HiFiveService";
import {WellbeingPanel} from "../WellbeingPanel/WellbeingPanel";
import {formatDateToISOTwelveHourClock, getStartDateForTimePeriodInDays} from "../../utilities/date/DateUtils";
import {InteractionReportPanel} from "../interactionReportPanel/InteractionReportPanel";
import "./Dashboard.css";
import {User} from "../../model/User";
import {ReportDescription} from "../charts/ReportDescription";
import {Profile} from "../profile/ProfileCard";
import {convertUsersToProfiles} from "../../mappers/UserMapper";
import {Survey} from "../../API";
import {surveyService} from "../../services/SurveyService/SurveyService";
import {Tab} from "../../model/Tab";
import {useLocation} from "react-router-dom";

type DashboardProps = {
    users: User[];
}

export const Dashboard: FC<DashboardProps> = ({users}) => {
    const [weeklyHiFivesResponse, setWeeklyHiFivesResponse] = useState<HiFiveResponse[]>([]);
    const [monthlyHiFivesResponse, setMonthlyHiFivesResponse] = useState<HiFiveResponse[]>([]);
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [isLoadingProfiles, setIsLoadingProfiles] = useState<boolean>(true);
    const [surveysResponse, setSurveysResponse] = useState<Survey[]>([]);
    const [isLoadingSurveys, setIsLoadingSurveys] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState<Tab>(Tab.Weekly);

    async function getWeeklyHiFives(): Promise<HiFiveResponse[]> {
        const today = new Date();
        const startDate = getStartDateForTimePeriodInDays(today, 7);
        return await hiFiveService.getHiFivesBetweenDates(formatDateToISOTwelveHourClock(startDate), formatDateToISOTwelveHourClock(today));
    }

    async function getMonthlyHiFives(): Promise<HiFiveResponse[]> {
        const today = new Date();
        const startDate = getStartDateForTimePeriodInDays(today, 28);
        return await hiFiveService.getHiFivesBetweenDates(formatDateToISOTwelveHourClock(startDate), formatDateToISOTwelveHourClock(today));
    }

    useEffect(() => {
        async function getAllHiFives(): Promise<void> {
            const weeklyHiFives = await getWeeklyHiFives();
            setWeeklyHiFivesResponse(weeklyHiFives);

            const monthlyHiFives = await getMonthlyHiFives();
            setMonthlyHiFivesResponse(monthlyHiFives);
        }

        async function getAllSurveys(): Promise<void> {
            const startDate = formatDateToISOTwelveHourClock(getStartDateForTimePeriodInDays(new Date(), 365));
            const surveys = await surveyService.getSurveysByUsersAndDate(users.map(user => user.id), startDate, formatDateToISOTwelveHourClock(new Date()));
            setSurveysResponse(surveys);

            setIsLoadingSurveys(false);
        }

        getAllHiFives();
        getAllSurveys();
    }, [users]);

    useEffect(() => {
        function getUserProfiles(): void {
            const profiles = convertUsersToProfiles(users, surveysResponse);
            setIsLoadingProfiles(false);
            setProfiles(profiles);
        }

        if (!isLoadingSurveys) {
            getUserProfiles();
        }
    }, [users, surveysResponse, isLoadingSurveys]);

    return (
        <>
            <NavBar previousPath={useLocation().pathname}/>
            <div className="dashboardGrid">
                <div className="chartDashboardPanel">
                    <ReportDescription activeTab={activeTab} setActiveTab={setActiveTab} />
                    <HiFiveChart
                        weeklyHiFives={weeklyHiFivesResponse}
                        monthlyHiFives={monthlyHiFivesResponse}
                        activeTab={activeTab}
                    />
                </div>
                {!isLoadingProfiles &&
                <>
                    <div className="toolbarDashboardPanel">
                        <WellbeingPanel profiles={profiles}/>
                    </div>
                    <div className="interactionReportDashboardPanel">
                        <InteractionReportPanel users={users} weeklyHiFives={weeklyHiFivesResponse} monthlyHiFives={monthlyHiFivesResponse} activeTab={activeTab} />
                    </div>
                </>
                }
            </div>
            <Footer/>
        </>
    );
};