/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHiFivesByDates = /* GraphQL */ `
  query GetHiFivesByDates($startDate: String!, $endDate: String!) {
    getHiFivesByDates(startDate: $startDate, endDate: $endDate) {
      items {
        id
        senderId
        recipientIds
        isPublic
        type
        dateCreated
        lastModified
        gsi1PartitionKey
        gsi2PartitionKey
      }
    }
  }
`;
export const getHiFivesByUserByDates = /* GraphQL */ `
  query GetHiFivesByUserByDates(
    $startDate: String!
    $endDate: String!
    $gsi2PartitionKey: String!
  ) {
    getHiFivesByUserByDates(
      startDate: $startDate
      endDate: $endDate
      gsi2PartitionKey: $gsi2PartitionKey
    ) {
      items {
        id
        senderId
        recipientIds
        isPublic
        type
        dateCreated
        lastModified
        gsi1PartitionKey
        gsi2PartitionKey
      }
    }
  }
`;
export const getSurveyByDates = /* GraphQL */ `
  query GetSurveyByDates($startDate: String!, $endDate: String!) {
    getSurveyByDates(startDate: $startDate, endDate: $endDate) {
      items {
        id
        userId
        questions {
          question
          answer
        }
        mood
        dateCreated
        gsi1PartitionKey
        gsi2PartitionKey
      }
    }
  }
`;
export const getSurveyByUserByDates = /* GraphQL */ `
  query GetSurveyByUserByDates(
    $startDate: String!
    $endDate: String!
    $gsi2PartitionKey: String!
  ) {
    getSurveyByUserByDates(
      startDate: $startDate
      endDate: $endDate
      gsi2PartitionKey: $gsi2PartitionKey
    ) {
      items {
        id
        userId
        questions {
          question
          answer
        }
        mood
        dateCreated
        gsi1PartitionKey
        gsi2PartitionKey
      }
    }
  }
`;
export const getSurveysByUser = /* GraphQL */ `
  query GetSurveysByUser($userId: String!) {
    getSurveysByUser(userId: $userId) {
      items {
        id
        userId
        questions {
          question
          answer
        }
        mood
        dateCreated
        gsi1PartitionKey
        gsi2PartitionKey
      }
    }
  }
`;
export const getAllUsers = /* GraphQL */ `
  query GetAllUsers {
    getAllUsers {
      items {
        id
        displayName
        realName
        jobTitle
        team
        profileImageUrl
      }
    }
  }
`;
export const getSurveyByUsersByDates = /* GraphQL */ `
  query GetSurveyByUsersByDates(
    $startDate: String!
    $endDate: String!
    $userIds: [String!]!
  ) {
    getSurveyByUsersByDates(
      startDate: $startDate
      endDate: $endDate
      userIds: $userIds
    ) {
      items {
        id
        userId
        questions {
          question
          answer
        }
        mood
        dateCreated
        gsi1PartitionKey
        gsi2PartitionKey
      }
    }
  }
`;
