import {ReportService} from "./ReportService";
import {User} from "../../model/User";
import {HiFive} from "../../model/HiFive";

export class ReportServiceImpl implements ReportService {

    private readonly RETURN_LIMIT = 4;

    private hiFives: HiFive[] = [];
    private allUsers?: User[] = [];
    private senderCounter: IdCounter = new IdCounter([]);
    private recipientCounter: IdCounter = new IdCounter([]);

    getMostAcknowledgedUsers(allUsers: User[], hiFives: HiFive[]): User[] {
        this.countSendersAndRecipients(hiFives);

        return this.recipientCounter
            .getMostFrequent(this.RETURN_LIMIT)
            .map(getUserByEntry(allUsers));
    }

    getLeastAcknowledgedUsers(allUsers: User[], hiFives: HiFive[]): User[] {
        this.countSendersAndRecipients(hiFives, allUsers);

        return this.recipientCounter
            .getLeastFrequent(this.RETURN_LIMIT)
            .map(getUserByEntry(allUsers));
    }

    getMostActiveUsers(allUsers: User[], hiFives: HiFive[]): User[] {
        this.countSendersAndRecipients(hiFives);

        return this.senderCounter
            .getMostFrequent(this.RETURN_LIMIT)
            .map(getUserByEntry(allUsers));
    }

    getLeastActiveUsers(allUsers:User[], hiFives:HiFive[]): User[] {
        this.countSendersAndRecipients(hiFives, allUsers);

        return this.senderCounter
            .getLeastFrequent(this.RETURN_LIMIT)
            .map(getUserByEntry(allUsers));
    }

    private countSendersAndRecipients(hiFives: HiFive[], allUsers: User[] = []): void {
        if (this.hasAlreadyBeenCounted(hiFives, allUsers)) {
            return;
        }

        const allUserIds: string[] = allUsers ? allUsers.map(user => user.id) : [];
        this.senderCounter = new IdCounter(allUserIds);
        this.recipientCounter = new IdCounter(allUserIds);

        hiFives.forEach(hiFive => {
            if (hiFive.senderId) {
                this.senderCounter.count(hiFive.senderId);
            }
            if (hiFive.recipientIds) {
                this.recipientCounter.countAll(hiFive.recipientIds);
            }
        });

        this.hiFives = hiFives;
        this.allUsers = allUsers;
    }

    // Returns "true" if the senders/recipients have already been counted
    // for this batch of hiFives and users. Saves us from re-counting.
    private hasAlreadyBeenCounted(hiFives: HiFive[], allUsers: User[]): boolean {
        return hiFives === this.hiFives && allUsers === this.allUsers;
    }
}

interface IdEntry {
    userId: string,
    occurrences: number,
}

class IdCounter {
    private readonly idCounts: Record<string, number> = {};

    constructor(ids: string[]) {
        ids.forEach(id => {
            this.idCounts[id] = 0;
        });
    }

    countAll(ids: string[]): void {
        ids.forEach(key => {
            this.count(key);
        });
    }

    count(id: string): void {
        this.idCounts[id] = this.contains(id) ? this.getFrequency(id) + 1 : 1;
    }

    getFrequency(id: string): number {
        return this.contains(id) ? this.idCounts[id] : 0;
    }

    getMostFrequent(limit: number): IdEntry[] {
        return this.getSortedEntries().slice(0, limit);
    }

    getLeastFrequent(limit: number): IdEntry[] {
        return this.getSortedEntries().reverse().slice(0, limit);
    }

    private contains(id: string): boolean {
        return id in this.idCounts;
    }

    // Sorts each entry in the occurrence map.
    // Most-frequent user IDs come first.
    // Returns a list of objects containing each ID and the number of times it occurred.
    private getSortedEntries(): IdEntry[] {
        return Object.entries(this.idCounts)
            .sort(([, frequencyA], [, frequencyB]) => frequencyB - frequencyA)
            .map(entry => ({userId: entry[0], occurrences: entry[1]}));
    }
}

function getUserByEntry(allUsers: User[]): (entry: IdEntry) => User {
    return entry => {
        for (const user of allUsers) {
            if (user.id === entry.userId){
                return user;
            }
        }
        console.error(`User not found by id '${entry.userId}'`);
        return {} as User;
    };
}
