import {VscSearch} from "react-icons/vsc";
import {FC} from "react";
import "./SearchBar.css";

interface SearchbarProps {
    searchText: string
    setSearchText: (input: string) => void
}

export const SearchBar: FC<SearchbarProps> = ({
    searchText,
    setSearchText
    }) => {
    return (
        <div className="searchBar">
            <VscSearch className="searchIcon"/>
            <input className="searchInput" type="text" placeholder="Search" value={searchText} onInput={e => {
                const target = e.target as HTMLButtonElement;
                setSearchText(target.value);
            }}/>
        </div>
    );
};