import {FC} from "react";
import "./NavMenu.css";
import {Footer} from "../footer/Footer";
import {MenuNavBar} from "../menuNavbar/MenuNavBar";
import {Link} from "react-router-dom";
import {signOut} from "../../services/Authentication/AuthenticationService";

export const NavMenu: FC = () => {
    type navMenuItem = {
        href: string,
        text: string
    }
    const navMenuItemContents: navMenuItem[] = [
        {href: "https://desks.instil.co/login", text: "02 Desk Hotel"},
        {href: "https://time.instil.co", text: "03 Timesheets"},
        {href: "https://instil.atlassian.net/wiki/home", text: "04 Confluence"},
        {href: "https://login.breathehr.com/login?", text: "05 BreatheHR"},
        {href: "https://instil.atlassian.net/jira", text: "06 Jira"}
    ];

    const getMenuIndex = (menuItems: navMenuItem[]): string => `${menuItems.length + 2}`.padStart(2, "0");

    return (
        <>
            <MenuNavBar/>
            <div className="menuItems">
                <li>
                    <Link className="listItemLink firstListItemLink" to="/dashboard">01 Hi-Five</Link>
                    <hr/>
                </li>
                {navMenuItemContents.map((
                    menuItem: navMenuItem): JSX.Element => {
                    return (
                        <li>
                            <a className="listItemLink" target={"_blank"} href={menuItem.href} rel="noopener noreferrer">{menuItem.text}</a>
                            <hr/>
                        </li>
                    );
                    }
                )}
                <li>
                    <Link to="/" className="listItemLink" onClick={signOut}>{`${getMenuIndex(navMenuItemContents)} Logout`}</Link>
                    <hr/>
                </li>
            </div>
            <Footer/>
        </>
    );
};