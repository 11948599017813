import {FC} from "react";
import "./TotalCount.css";

interface TotalCountProps {
    name: string,
    count: number
}

export const TotalCount: FC<TotalCountProps> = ({name, count}) => {
    return (
        <div className="flex">
            <h3 className={name === "Hi-Fives" ? "primary-blue count" : "primary-green count"}>{count}</h3>
            <p className="type">{name}</p>
        </div>
    );
};