import {FC} from "react";
import "./Login.css";
import {signIn} from "../../services/Authentication/AuthenticationService";
import logo from "../navbar/InsideInstil.png";
import googleIcon from "./GoogleIcon.png";

export const Login: FC = () => {
    return (
        <div className="container">
            <div className="flex-col">
                <img className="loginLogo" src={logo} alt="Inside Instil Logo"/>
            </div>
            <div className="flex-col">
                <div className="heading">Log in to your account</div>
            </div>
            <div>
                <div className="description">Welcome back! Please sign in.</div>
            </div>
            <div>
                <button className="login-button" onClick={signIn}>
                    <img src={googleIcon} alt="Google"/>Sign in with Google
                </button>
            </div>
        </div>
    );
};