import {FC, useEffect, useState} from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import "./HiFiveChart.css";
import {TotalCount} from "./TotalCount";
import {HiFiveGraphPlot} from "../../model/HiFiveGraphPlot";
import {mapToMonthlyHiFiveGraphPlots, mapToWeeklyHiFiveGraphPlots} from "../../mappers/HiFiveGraphMapper";
import {HiFiveResponse} from "../../utilities/types/AWSResponses";
import {Tab} from "../../model/Tab";

type KudosType = "HiFive" | "LoFive";

interface HiFiveChartProps {
    weeklyHiFives: HiFiveResponse[];
    monthlyHiFives: HiFiveResponse[];
    activeTab: Tab;
}

export const HiFiveChart: FC<HiFiveChartProps> = ({
    weeklyHiFives,
    monthlyHiFives,
    activeTab
}) => {
    const [weeklyHiFiveGraphPlots, setWeeklyHiFiveGraphPlots] = useState<HiFiveGraphPlot[]>([]);
    const [monthlyHiFiveGraphPlots, setMonthlyHiFiveGraphPlots] = useState<HiFiveGraphPlot[]>([]);

    useEffect(() => {
        setWeeklyHiFiveGraphPlots(mapToWeeklyHiFiveGraphPlots(weeklyHiFives));
        setMonthlyHiFiveGraphPlots(mapToMonthlyHiFiveGraphPlots(monthlyHiFives));
    }, [weeklyHiFives, monthlyHiFives]);

    function graphData(): HiFiveGraphPlot[] {
        return activeTab === Tab.Weekly ? weeklyHiFiveGraphPlots : monthlyHiFiveGraphPlots;
    }

    function calculateTotal(kudosType: KudosType): number {
        return graphData().reduce((accumulator, plot) => {
            return kudosType === "HiFive" ? accumulator + plot.hiFives : accumulator + plot.loFives;
        }, 0);
    }

    return (
        <div className="chart">
            <ResponsiveContainer width="100%" height={250} className="responsiveContainer">
                <LineChart data={graphData()}
                           margin={{top: 5, right: 80, left: 20, bottom: 5}}>
                    <XAxis dataKey="name" tick={{fill: "#ADADAD", fontSize: "10px"}}/>
                    <YAxis
                        label={{value: "Hi/Lo-Fives", angle: -90, textAnchor: "insideLeft", dx: -30, fill: "#ADADAD"}}
                        tick={{fill: "#ADADAD", fontSize: "10px"}}
                        allowDecimals={false}
                    />
                    <CartesianGrid/>
                    <Tooltip/>
                    <Line name="Hi Fives" type="monotone" dataKey="hiFives" stroke="#164CFF" isAnimationActive={false}/>
                    <Line name="Lo Fives" type="monotone" dataKey="loFives" stroke="#29CC6A" isAnimationActive={false}/>
                </LineChart>
            </ResponsiveContainer>
            <div className="grid">
                {weeklyHiFiveGraphPlots &&
                <>
                    <TotalCount name={"Hi-Fives"} count={calculateTotal("HiFive")}/>
                    <TotalCount name={"Lo-Fives"} count={calculateTotal("LoFive")}/>
                </>
                }
            </div>
        </div>
    );
};