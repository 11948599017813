import {FC} from "react";
import logo from "./InsideInstil.png";
import "./NavBar.css";
import {GiHamburgerMenu} from "react-icons/gi";
import {Link} from "react-router-dom";

type NavBarProps = {
    previousPath: string;
}

export const NavBar: FC<NavBarProps> = ({previousPath}) => {
    return (
        <div className="navbar">
            <Link to="/dashboard" className="logo"><img className="logo" src={logo} alt="Instil Logo"/></Link>
            <div className="nav-items">
                    <Link to="/dashboard" className="current"><div>Hi-Five</div></Link>
                    <a target={"_blank"} href="https://desks.instil.co/login" rel="noreferrer"><div>Desk Hotel</div></a>
                    <a target={"_blank"} href="https://time.instil.co" rel="noreferrer"><div>Timesheets</div></a>
            </div>
            <div className="menuNavItem"><Link to="/menu" state={{previousPath}}> <GiHamburgerMenu size={"2rem"}/> </Link></div>
        </div>
    );
};