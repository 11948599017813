import React, {FC} from "react";
import DownArrow from "./toolbarIcons/DownArrow.png";
import GreySideArrow from "./toolbarIcons/GreySideArrow.png";
import activeCheck from "./toolbarIcons/active.png";
import hoverCheck from "./toolbarIcons/hover.png";
import defaultCheck from "./toolbarIcons/default.png";
import {Filter} from "./WellbeingPanel";
import "./FilterPanel.css";

interface FilterProps {
    filterId: string,
    currentlySelectedFilterId: string,
    setCurrentlySelectedFilterId: (id: string) => void,
    addToSelectedFilters: (filter: Filter) => void,
    removeSelectedFilter: (filter: Filter) => void,
    filterOptions: Filter[],
    alreadySelectedFilters: Filter[],
    filterText: string
}

export const FilterPanel: FC<FilterProps> = ({
        filterId,
        currentlySelectedFilterId,
        setCurrentlySelectedFilterId,
        addToSelectedFilters,
        removeSelectedFilter,
        filterOptions,
        alreadySelectedFilters,
        filterText
    }: FilterProps): JSX.Element => {
    const showDropDown = (filterId === currentlySelectedFilterId);

    function toggleDropDown(): void {
        setCurrentlySelectedFilterId(showDropDown ? "" : filterId);
    }

    const hideDropdown = (e: React.FocusEvent): void => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setCurrentlySelectedFilterId("");
        }
    };

    const isSelectedFilter = (chosenFilter: Filter): boolean =>
        (alreadySelectedFilters.filter(filter => (filter.value === chosenFilter.value && filter.type === chosenFilter.type)).length > 0);

    const processKeyboardInput = (e: React.KeyboardEvent, callback: () => void): void => {
        if (e.key === "Enter" || e.key === " ") {
            callback();
        }
    };

    const processFilterAction = (filter: Filter): void => isSelectedFilter(filter) ? removeSelectedFilter(filter) : addToSelectedFilters(filter);

    const checkFilterType = (filter: Filter): boolean => filter.type.toLowerCase() === filterId.toLowerCase();

    return (
        <>
            <button
                className={showDropDown ? "active" : undefined}
                tabIndex={-1}
                onClick={(): void => toggleDropDown()}
                onBlur={(e: React.FocusEvent<HTMLButtonElement>): void => hideDropdown(e)}
            >
                <div className={showDropDown ? "active" : undefined}
                     tabIndex={0}
                     onBlur={(e: React.FocusEvent<HTMLDivElement>): void => hideDropdown(e)}
            >
                    <div
                        className={(showDropDown || alreadySelectedFilters.length > 0) ? "selectedFilter" : "unselectedFilter"}>
                        {filterText === "JobTitle" ? "Craft" : filterText}
                        <img
                            className={(showDropDown || alreadySelectedFilters.filter(checkFilterType).length > 0) ? "showArrow arrowStyle" : "hideArrow"}
                            src={DownArrow} alt="Down Arrow"/>
                        <img
                            className={(showDropDown || alreadySelectedFilters.filter(checkFilterType).length > 0) ? "hideArrow" : "showArrow arrowStyle"}
                            src={GreySideArrow} alt="Side Arrow"/>
                    </div>
                    {showDropDown && (
                        <div className={showDropDown ? "dropdown" : "dropdown active"}
                             onBlur={(e) => hideDropdown(e)}
                        >
                            {filterOptions.map(
                                (filter: Filter, index: number): JSX.Element => {
                                    return (
                                        <p
                                            tabIndex={0}
                                            className="dropdownFilters"
                                            key={index}
                                            onClick={(): void => processFilterAction(filter)}
                                            onKeyPress={(e) => processKeyboardInput(e, () => processFilterAction(filter))}
                                        >
                                            <img src={defaultCheck}
                                                 className={isSelectedFilter(filter) ? "hasBeenSelected" : "defaultCheckBox checkboxIcon"}
                                                 alt="Unfilled Checkbox"/>
                                            <img src={activeCheck}
                                                 className={isSelectedFilter(filter) ? "checkedFilter checkboxIcon" : "notSelected"}
                                                 alt="Filled checkBox"/>
                                            <img src={hoverCheck}
                                                 className={isSelectedFilter(filter) ? "hasBeenSelected" : "hoverCheckbox checkboxIcon"}
                                                 alt="Unfilled CheckBox"/>
                                            {filter.value}
                                        </p>
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
            </button>
        </>
    );
};