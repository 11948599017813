import {SurveyService} from "./SurveyService";
import {executeGraphQlOperation} from "../../utilities/graphql/HelperFunctions";
import {graphqlOperation} from "aws-amplify";
import {SurveyResponse} from "../../utilities/types/AWSResponses";
import {GetSurveyByUserByDatesQuery, GetSurveysByUserQuery, GetSurveyByUsersByDatesQuery} from "../../API";
import {getSurveyByUserByDates, getSurveysByUser, getSurveyByUsersByDates} from "../../graphql/queries";

export class SurveyServiceImpl implements SurveyService {
    getSurveysByUser = async (userId: string): Promise<SurveyResponse[]> => {
      try {
        const surveyResponse = await executeGraphQlOperation<GetSurveysByUserQuery>(graphqlOperation(getSurveysByUser, {
            userId
        }));

        return surveyResponse.data?.getSurveysByUser?.items as SurveyResponse[];
      } catch (error) {
        console.log(`Failed to fetch survey results by User: ${error}`);
        return [] as SurveyResponse[];
      }
    };

    getSurveysByUserAndDate = async (userId: string, startDate: string, endDate: string): Promise<SurveyResponse[]> => {
      try {
        const surveyResponse = await executeGraphQlOperation<GetSurveyByUserByDatesQuery>(graphqlOperation(getSurveyByUserByDates, {
          startDate,
          endDate,
          gsi2PartitionKey: `SURVEY-RESPONSE-${userId}`
        }));

        return surveyResponse.data?.getSurveyByUserByDates?.items.reverse() as SurveyResponse[];
      } catch (error) {
        console.log(`Failed to fetch survey results by User and Date: ${error}`);
        return [] as SurveyResponse[];
      }
    };

    getSurveysByUsersAndDate = async (userIds: string[], startDate: string, endDate: string): Promise<SurveyResponse[]> => {
      try {
        const surveyResponse = await executeGraphQlOperation<GetSurveyByUsersByDatesQuery>(graphqlOperation(getSurveyByUsersByDates, {
          startDate,
          endDate,
          userIds
        }));
        return surveyResponse.data?.getSurveyByUsersByDates?.items.reverse() as SurveyResponse[];
      } catch (error) {
        console.log(`Failed to fetch survey results by Users and Date: ${error}`);
        return [] as SurveyResponse[];
      }
    };
}