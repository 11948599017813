import {FC, useEffect, useState} from "react";
import "./SurveyReplies.css";
import {surveyService} from "../../services/SurveyService/SurveyService";
import {SurveyQuestion} from "../../API";
import {formatDateToISOTwelveHourClock, getStartDateForTimePeriodInDays} from "../../utilities/date/DateUtils";
import {User} from "../../model/User";

interface SurveyResponseProps{
    index: number,
    question: string,
    user: User
}

interface Survey {
    id: string,
    userId: string,
    questions: SurveyQuestion[],
    mood: string,
    dateCreated: string,
    gsi1PartitionKey: string,
    gsi2PartitionKey: string
}

export const SurveyReplies: FC<SurveyResponseProps> = ({
    index,
    question,
    user
}) => {
    const defaultNumberOfShownReplies = 4;
    const [surveys, setSurveys] = useState<Survey[]>([]);
    const [itemsToShow, setItemsToShow] = useState(defaultNumberOfShownReplies);

    function getSurveyAnswers(answerIndex: number): string{
        const surveyAnswers = surveys.map(survey => survey.questions[index].answer);
        return surveyAnswers[answerIndex] as string;
    }

    useEffect(() => {
        async function getSurveysByUser(): Promise<void> {
            const startDate = formatDateToISOTwelveHourClock(getStartDateForTimePeriodInDays(new Date(), 365));
            const surveys = await surveyService.getSurveysByUserAndDate(user.id, startDate, formatDateToISOTwelveHourClock(new Date()));
            setSurveys(surveys);
        }

        getSurveysByUser();
    }, [user.id]);

    const showMore = ():void => setItemsToShow(surveys.length);
    const showLess = ():void => setItemsToShow(defaultNumberOfShownReplies);

    return (
      <>
        {surveys.length > 0 && <div className="surveyReplyPanel">
          <div className="question">
            <span>{index + 1}</span> {`${question}?`}
          </div>

          <div>
            <>
            {surveys.slice(0, itemsToShow).map((_survey, index) =>
              <div className="answerGrid" key={index}>
                <span>{surveys[index].dateCreated}</span>
                {getSurveyAnswers(index)}
              </div>
            )}

            {(surveys.length > defaultNumberOfShownReplies) ?
              (itemsToShow === defaultNumberOfShownReplies) ?
                <button onClick={showMore}>View remaining replies ({surveys.length - defaultNumberOfShownReplies})</button>: <button onClick={showLess}>View less replies</button> : <></>}
            </>
          </div>

          </div>
        }
      </>
    );
};