import {FC} from "react";
import "./Activity.css";

export const Activity: FC = () => {
    return (
        <div className="activity">
            <b>Activity</b>
            <span className={"dot hiFive"}></span>Hi-Fives (Public)
            <span className={"dot loFive"}></span>Lo-Fives (Private)
        </div>
    );
};