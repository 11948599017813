import {HiFiveService} from "./HiFiveService";
import {executeGraphQlOperation} from "../../utilities/graphql/HelperFunctions";
import {GetHiFivesByDatesQuery} from "../../API";
import {graphqlOperation} from "aws-amplify";
import {getHiFivesByDates} from "../../graphql/queries";
import {HiFiveResponse} from "../../utilities/types/AWSResponses";
import {formatDateForGraph} from "../../utilities/date/DateUtils";

export class HiFiveServiceImpl implements HiFiveService {
    getHiFivesBetweenDates = async (startDate: string, endDate: string): Promise<HiFiveResponse[]> => {
        const hiFivesResponse = await executeGraphQlOperation<GetHiFivesByDatesQuery>(graphqlOperation(getHiFivesByDates, {
            startDate, endDate
        }));

        const hiFives = hiFivesResponse.data?.getHiFivesByDates?.items as HiFiveResponse[];
        return hiFives.map(hiFive => {
            hiFive.dateCreated = formatDateForGraph(hiFive.dateCreated);
            return hiFive;
        });
    };
}