import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import {User} from "../../model/User";

const ADMIN_GROUP_NAME = "hifiveadmins";

export async function signIn(): Promise<void> {
    try {
        await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
    } catch (error) {
        console.error(`Failed to sign in to Google: ${error}`);
        throw error;
    }
}

export const signOut = (): Promise<User> => {
    try {
        return Auth.signOut();
    } catch (err) {
        console.log(`Failed to sign out of Google: ${err}`);
        throw err;
    }
};

export async function checkUserHasAdminGroup(): Promise<boolean> {
    const cognitoUserSession = await Auth.currentSession();
    const groups = cognitoUserSession.getIdToken().payload["cognito:groups"] as string[];
    return groups.includes(ADMIN_GROUP_NAME);
}