import {Dispatch, FC, SetStateAction} from "react";
import {Activity} from "./Activity";
import "./ReportDescription.css";
import {Tab} from "../../model/Tab";

interface ReportDescriptionProps {
    activeTab: Tab;
    setActiveTab: Dispatch<SetStateAction<Tab>>
}

export const ReportDescription: FC<ReportDescriptionProps> = ({activeTab, setActiveTab}) => {
    return (
        <div>
            <div className="title-section flex-between">
                <h1>Hi-Five Report</h1>
                <div className="flex-between">
                    <p
                        tabIndex={0}
                        onKeyDown={onSpaceBarOrEnter(() => setActiveTab(Tab.Monthly))}
                        onClick={() => setActiveTab(Tab.Monthly)}
                        className={activeTab === Tab.Monthly ? "tab-item selected" : "tab-item unselected"}>Last 4 Weeks</p>
                    <p
                        tabIndex={0}
                        onKeyDown={onSpaceBarOrEnter(() => setActiveTab(Tab.Weekly))}
                        onClick={() => setActiveTab(Tab.Weekly)}
                        className={activeTab === Tab.Weekly ? "tab-item selected" : "tab-item unselected"}>Last 7 Days</p>
                </div>
            </div>
            <Activity />
        </div>
    );
};

function onSpaceBarOrEnter(callback: () => void) {
    return (e: React.KeyboardEvent) => {
        if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            callback();
        }
    };
}