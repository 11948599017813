import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {Amplify} from "aws-amplify";
import awsConfig from "./custom-aws-exports";
import {BrowserRouter} from "react-router-dom";

Amplify.configure(awsConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <App />
      </BrowserRouter>
  </React.StrictMode>
);
