import {Survey} from "../API";
import {MoodEmojiDetails} from "../components/userMoodSummary/UserMoodSummary";
import {capitalise} from "../utilities/string/StringUtils";
import {Filter} from "../components/WellbeingPanel/WellbeingPanel";

export const moods = ["Unknown", "Miserable", "Sad", "Neutral", "Happy", "Great"] as const;

export type Mood = typeof moods[number];

interface MoodValueDetails {
    totalMoodValue: number,
    numberOfUnknowns: number
}

const baseUrl = "/images/MoodEmojis";
export const unknownEmoji = `${baseUrl}/Unknown.png`;
export const greatEmoji = `${baseUrl}/Great.png`;
export const happyEmoji = `${baseUrl}/Happy.png`;
export const neutralEmoji = `${baseUrl}/Neutral.png`;
export const sadEmoji = `${baseUrl}/Sad.png`;
export const miserableEmoji = `${baseUrl}/Miserable.png`;

export function getEmojiFromMood(mood: Mood): string {
    switch (capitalise(mood)) {
        case "Great":
            return greatEmoji;
        case "Happy":
            return happyEmoji;
        case "Neutral":
            return neutralEmoji;
        case "Sad":
            return sadEmoji;
        case "Miserable":
            return miserableEmoji;
        default:
            return unknownEmoji;
    }
}

export function get2WeekAverageMood(firstWeekBeingCalculated: number, surveys: Survey[]): MoodEmojiDetails {
    if (surveys.length < firstWeekBeingCalculated) return {src: unknownEmoji, altDescription: "Unknown"};
    return calculateAverageMood(firstWeekBeingCalculated, surveys, 2);
}

export function getUserAverageMood(surveys: Survey[]): MoodEmojiDetails {
    if (surveys.length === 0) return {src: unknownEmoji, altDescription: "Unknown"};
    return calculateAverageMood(surveys.length, surveys, surveys.length);
}

export function getFilterOptionsForMood(): Filter[] {
    return moods.map(mood => {
        return {type: "mood", value: mood};
    });
}

function calculateAverageMood(firstWeekBeingCalculated: number, surveys: Survey[], numberOfWeeksBeingCalculated: number): MoodEmojiDetails {
    const moodValueDetails = getMoodValueDetailsFromMood(firstWeekBeingCalculated, surveys, numberOfWeeksBeingCalculated);
    const averageMoodValue = (moodValueDetails.numberOfUnknowns < numberOfWeeksBeingCalculated) ? Math.floor(moodValueDetails.totalMoodValue / (numberOfWeeksBeingCalculated - moodValueDetails.numberOfUnknowns)) : 6;
    return getEmojiFromAverageMoodValue(averageMoodValue);
}

function getMoodValueDetailsFromMood(firstWeekBeingCalculated: number, surveys: Survey[], numberOfWeeksBeingCalculated: number): MoodValueDetails {
    let numberOfUnknowns = 0;
    let totalMoodValue = 0;
    for (let i = firstWeekBeingCalculated; i !== firstWeekBeingCalculated - numberOfWeeksBeingCalculated; i--) {
        const mood = capitalise(surveys[surveys.length - i].mood);
        (mood === "Unknown") ? numberOfUnknowns += 1 : totalMoodValue += moods.indexOf(mood as Mood);
    }
    return {totalMoodValue, numberOfUnknowns};
}

function getEmojiFromAverageMoodValue(averageMoodValue: number): MoodEmojiDetails {
    const allMoodsAreUnknown = 6;
    if (averageMoodValue === allMoodsAreUnknown) return {src: unknownEmoji, altDescription: "Unknown"};
    const altDescription = moods[averageMoodValue];
    return {src: getEmojiFromMood(altDescription), altDescription: altDescription};
}